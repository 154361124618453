<div class="request-container">
  <div class="app-bar">
    <div class="logo-box">
      <img src="assets/images/mfaic-logo.webp" class="logo" alt="" srcset="" />
      <h1 class="app-name">{{ "app_name" | translate }}</h1>
    </div>
    <span class="spacer"></span>
    <div class="user-box">
      <p class="name">{{ username }}</p>
      <p class="division">{{ userDivision }}</p>
    </div>
    <app-profile-menu />
  </div>
  <div class="content-wrapper">
    <router-outlet />
  </div>
</div>
