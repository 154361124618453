import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileMenuComponent } from '../../shared/components/profile-menu/profile-menu.component';
import { RouterModule } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';
import { LocalStorageEnum } from '../../models/enums/local-storage-enum';

@Component({
  selector: 'app-requester-container',
  standalone: true,
  imports: [TranslateModule, ProfileMenuComponent, RouterModule],
  templateUrl: './requester-container.component.html',
  styleUrl: './requester-container.component.scss',
})
export class RequesterContainerComponent {
  constructor(private _localStorageService: LocalStorageService) {}

  username =
    this._localStorageService.get(LocalStorageEnum.user_firstname) +
    ' ' +
    this._localStorageService.get(LocalStorageEnum.user_lastname);
  userDivision = this._localStorageService.get(
    LocalStorageEnum.user_division_name
  );
}
