import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserRoleEnum } from '../models/enums/user-role-enum';

export const notLoginGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService.isAuth) {
    return true;
  } else {
    if (authService.userRole === UserRoleEnum.Admin) {
      router.navigate(['dashboard']);
    }
    if (authService.userRole === UserRoleEnum.Requester) {
      router.navigate(['requests']);
    }
    if (authService.userRole === UserRoleEnum.Meeting_Requester) {
      router.navigate(['meeting-requester']);
    }
    return false;
  }
};
