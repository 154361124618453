import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserRoleEnum } from '../models/enums/user-role-enum';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (
    authService.isAuth &&
    (authService.userRole === UserRoleEnum.Admin ||
      authService.userRole === UserRoleEnum.Updater)
  ) {
    if (authService.userRole === UserRoleEnum.Updater) {
      if (
        route.url.at(0).path === 'request-management' ||
        route.url.at(0).path === 'meeting-request-management'
      ) {
        return true;
      } else {
        router.navigateByUrl('/login');
        return false;
      }
    } else {
      return true;
    }
  } else {
    router.navigateByUrl('/login');
    return false;
  }
};
