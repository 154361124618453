import {
  HttpClient,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { LocalStorageEnum } from '../models/enums/local-storage-enum';
import { environment } from '../../environments/environment';
import { LocalStorageService } from '../services/local-storage.service';
import { BaseApiResponse } from '../models/bases/base-api-response';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const localStorageService = inject(LocalStorageService);
  const http = inject(HttpClient);
  let isRefreshingToken: boolean = false;
  const tokenBehaviorSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  const refresh_token_url = environment.api_url + '/refresh-token';
  const refresh_token = localStorageService.get(LocalStorageEnum.refresh_token);
  let token = localStorageService.get(LocalStorageEnum.token);
  if (req.url == refresh_token_url) {
    req = addRefreshToken(req, refresh_token);
  } else if (token) {
    req = addToken(req, token);
  }
  return next(req).pipe(
    catchError((err) => {
      if (err.status === 401) {
        if (req.url === refresh_token_url) {
          logout();
        } else if (refresh_token) {
          if (!isRefreshingToken) {
            isRefreshingToken = true;
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            tokenBehaviorSubject.next('');
            // get a new token via userService.refreshToken
            return http.post<BaseApiResponse>(refresh_token_url, {}).pipe(
              switchMap((res) => {
                localStorageService.set(
                  LocalStorageEnum.token,
                  res.data.access_token
                );
                const currentDate = new Date();
                const currentMillisecond = currentDate.getTime();
                // convert to millisecond since response from server is in second
                const tokenExpiresInMillsecond = res.data.expires_in * 1000;
                const expiresAt = currentMillisecond + tokenExpiresInMillsecond;
                localStorageService.set(
                  LocalStorageEnum.token_expires_at,
                  String(expiresAt)
                );
                tokenBehaviorSubject.next(res.data.access_token);
                return next(addToken(req, res.data.access_token));
              }),
              catchError((err) => {
                // If we don't get a new token, we are in trouble so logout.
                logout();
                return throwError(() => err);
              }),
              finalize(() => {
                isRefreshingToken = false;
              })
            );
          } else {
            return tokenBehaviorSubject.pipe(
              filter((token) => token != ''),
              take(1),
              switchMap((token) => {
                return next(addToken(req, token));
              })
            );
          }
        } else {
          logout();
        }
      }
      return throwError(() => err);
    })
  );
};

const addToken = (req: HttpRequest<any>, token: string) => {
  return req.clone({
    setHeaders: {
      Authorization: 'Bearer ' + token,
    },
  });
};

const addRefreshToken = (req: HttpRequest<any>, token: string) => {
  return req.clone({
    body: { ...req.body, refresh_token: token },
  });
};

const logout = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  authService.logout().subscribe((res) => {
    router.navigateByUrl('/login');
  });
};
