import {
  ApplicationConfig,
  LOCALE_ID,
  Provider,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { authInterceptor } from './interceptors/auth.interceptor';

import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import km from '@angular/common/locales/km';
import { MAT_DATE_LOCALE } from '@angular/material/core';

registerLocaleData(km);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const localeProvider: Provider = {
  provide: LOCALE_ID,
  useValue: 'km-KH',
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([authInterceptor])),
    importProvidersFrom([
      TranslateModule.forRoot({
        defaultLanguage: 'km',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ]),
    provideAnimations(),
    DatePipe,
    CurrencyPipe,
    localeProvider,
  ],
};
