@if (userRole === userRoleEnum.Admin || userRole === userRoleEnum.Updater) {
<mat-drawer-container class="main-container">
  <mat-drawer [mode]="drawerMode" [opened]="opened">
    <div class="nav-wrapper">
      <img src="assets/images/app-logo.png" alt="" class="logo" />

      @if (userRole === userRoleEnum.Updater) {
        <mat-list>
          @if (menu.dashboard) { @for (item of menu.dashboard; track $index) {
          <mat-list-item
            matRipple
            [routerLink]="item.route"
            routerLinkActive="active-route"
          >
            <mat-icon matListItemIcon [svgIcon]="item.icon!"></mat-icon>
            <span>{{ item.title | translate }}</span>
          </mat-list-item>
          } } @if (menu.requestManagement) { @for (item of menu.requestManagement;
          track $index) {
          <mat-list-item
            matRipple
            [routerLink]="item.route"
            routerLinkActive="active-route"
          >
            <mat-icon matListItemIcon [svgIcon]="item.icon!"></mat-icon>
            <span>{{ item.title | translate }}</span>
          </mat-list-item>
  
          } } 
          @if (menu.meetingRequestManagement) { @for (item of menu.meetingRequestManagement;
            track $index) {
            <mat-list-item
              matRipple
              [routerLink]="item.route"
              routerLinkActive="active-route"
            >
              <mat-icon matListItemIcon [svgIcon]="item.icon!"></mat-icon>
              <span>{{ item.title | translate }}</span>
            </mat-list-item>
            } }
        </mat-list>
      }

      @if (userRole === userRoleEnum.Admin) {
         <mat-list>
        @if (menu.dashboard) { @for (item of menu.dashboard; track $index) {
        <mat-list-item
          matRipple
          [routerLink]="item.route"
          routerLinkActive="active-route"
        >
          <mat-icon matListItemIcon [svgIcon]="item.icon!"></mat-icon>
          <span>{{ item.title | translate }}</span>
        </mat-list-item>
        } } @if (menu.requestManagement) { @for (item of menu.requestManagement;
        track $index) {
        <mat-list-item
          matRipple
          [routerLink]="item.route"
          routerLinkActive="active-route"
        >
          <mat-icon matListItemIcon [svgIcon]="item.icon!"></mat-icon>
          <span>{{ item.title | translate }}</span>
        </mat-list-item>

        } } 
        @if (menu.meetingRequestManagement) { @for (item of menu.meetingRequestManagement;
          track $index) {
          <mat-list-item
            matRipple
            [routerLink]="item.route"
            routerLinkActive="active-route"
          >
            <mat-icon matListItemIcon [svgIcon]="item.icon!"></mat-icon>
            <span>{{ item.title | translate }}</span>
          </mat-list-item>
  
          } }

        @if(menu.report) { @for (item of menu.report; track item) {
        <mat-list-item
          matRipple
          class="stroke-icon"
          [routerLink]="item.route"
          routerLinkActive="active-route"
        >
          <mat-icon matListItemIcon [svgIcon]="item.icon!"></mat-icon>
          <span>{{ item.title | translate }}</span>
        </mat-list-item>
        } } @if (menu.settings.length > 0) {
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon svgIcon="settings"></mat-icon>
              <span>{{ "settings" | translate }}</span>
            </mat-panel-title></mat-expansion-panel-header
          >
          @for (item of menu.settings; track $index) {
          <mat-list-item
            matRipple
            class="expand-panel-item"
            [routerLink]="item.route"
            routerLinkActive="active-route"
          >
            <span>{{ item.title | translate }}</span>
          </mat-list-item>
          }
        </mat-expansion-panel>
        }
      </mat-list>
      }
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <mat-toolbar>
      <mat-icon (click)="toggleMenu()" style="cursor: pointer">menu</mat-icon>
      <span class="page-title"
        >{{
          pageType !== "dashboard" && pageType !== "request-management" && pageType !== 'meeting-request-management'
            ? (pageType | translate)
            : ""
        }}{{ pageTitle | translate }}</span
      >
      <div class="spacer"></div>
      <div class="user-box">
        <p class="name">{{ username }}</p>
        <p class="role">{{ userRole }}</p>
      </div>
      <app-profile-menu />
    </mat-toolbar>
    <div class="app-content">
      <router-outlet />
    </div>
  </mat-drawer-content>
</mat-drawer-container>
} @else {
<!-- <div class="updater-container">
  <div class="app-bar">
    <div class="logo-box">
      <img src="assets/images/mfaic-logo.webp" class="logo" alt="" srcset="" />
      <h1 class="app-name">{{ "app_name" | translate }}</h1>
    </div>
    <span class="spacer"></span>
    <div class="user-box">
      <p class="name">{{ username }}</p>
      <p class="division">{{ userRole }}</p>
    </div>
    <app-profile-menu />
  </div>
  <div class="content-wrapper">
    <router-outlet />
  </div>
</div> -->
}
