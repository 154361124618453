import { Component } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'budget-request-mfaic-backoffice';
  isLoading = false;
  loadingTimeout: any;
  constructor(private _router: Router, public loadingService: LoadingService) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.forceStop();
        this.loadingService.setLoading(true);
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        // scroll to top on navigate finish
        // window.scrollTo({top:0})
        this.loadingService.setLoading(false);
      }
    });
    // delay to hide some quick loading
    this.loadingService.isLoading$.subscribe((isLoading: boolean) => {
      if (this.loadingTimeout) {
        clearTimeout(this.loadingTimeout);
      }
      this.loadingTimeout = setTimeout(() => {
        this.isLoading = isLoading;
        this.loadingTimeout = null;
      }, 200);
    });
  }
}
