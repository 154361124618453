<div class="profile-box">
  <div class="profile" [matMenuTriggerFor]="profileMenu">
    <img src="assets/images/user.png" alt="" srcset="" />
  </div>
  <mat-menu #profileMenu="matMenu" class="action-menu">
    <button mat-menu-item (click)="passwordChangeChange()" class="pwd-btn">
      <mat-icon>lock_reset</mat-icon>
      <span>{{ "actions.change_password" | translate }}</span>
    </button>
    <button mat-menu-item (click)="logoutHandler()" class="delete-btn">
      <mat-icon>logout</mat-icon>
      <span>{{ "logout" | translate }}</span>
    </button>
  </mat-menu>
</div>
